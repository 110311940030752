import styled from "styled-components"
import Link from "next/link"
import DateFormatter from "../DateFormatter"
import { Tags } from "../Tags/Tags"

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`

const StyledTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: bold;
  line-height: 32px;
  color: ${(props) => props.theme.palette.colors.typography[1000]};
`

const StyledDate = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.palette.colors.typography[900]};
`
const StyledPreviewText = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.palette.colors.typography[1000]};
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  * {
    color: ${(props) => props.theme.palette.colors.typography[1000]};
    font-size: 16px;
  }
`

const StyledTags = styled(Tags)`
  margin-top: 16px;
`

export function PostCard({ item }) {
  const { title, name, date, content, slug, excerpt, tags } = item
  return (
    <>
      <Link href={`/posts/${slug}`} style={{ textDecoration: "none" }} title={title}>
        <StyledContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledDate>
            <DateFormatter dateString={date} />
          </StyledDate>

          <StyledPreviewText
            dangerouslySetInnerHTML={{ __html: excerpt }}
          ></StyledPreviewText>
        </StyledContainer>
      </Link>
      {tags && <StyledTags items={tags} />}
    </>
  )
}
