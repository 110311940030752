import React from "react"
import styled from "styled-components"
import { Tag } from "./Tag"

const StyledContainer = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
`

export const Tags = ({ className, items }) => {
  if (!items) {
    return null
  }
  return (
    <StyledContainer className={className}>
      {items.map((item) => (
        <Tag text={item} path={`/tag/${item}`} key={item} />
      ))}
    </StyledContainer>
  )
}
