import React, { Fragment, useEffect } from "react"
import styled from "styled-components"
import { Divider } from "@zenury/ui-components"
import { getAllPosts } from "../lib/api"
import { PostCard } from "../components/PostCard"
import { getSizes } from "../components/styles"
import { Tags } from "../components/Tags/Tags"
import { useAppContext } from "../contexts"
import {
  APP_DESCRIPTION,
  APP_KEYWORDS,
  APP_TITLE,
  APP_URL,
  revalidateNext,
} from "../utils/constants"
import MetaTags from "./MetaTags"

const StyledPosts = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  width: 100%;

  ${getSizes};
`

const StyledDivider = styled(Divider)`
  margin: 28px 0;
`

const StyledTitle = styled.h1`
  margin: 0;
  font-weight: bold;
  color: ${(props) => props.theme.palette.colors.typography[1000]};
  margin-bottom: 24px;
  font-size: 36px;
`

const StyledTopics = styled.div`
  margin-bottom: 24px;
`

export default function Home({ allPosts, tags }) {
  const { initFunc } = useAppContext()
  useEffect(() => {
    initFunc({ posts: allPosts, tags })
  }, [allPosts, tags])

  if (!tags || !allPosts) {
    return <div />
  }

  return (
    <>
      <MetaTags
        title={APP_TITLE}
        description={APP_DESCRIPTION}
        url={APP_URL}
        keywords={APP_KEYWORDS}
      />
      <StyledPosts>
        {tags && (
          <StyledTopics>
            <StyledTitle>Explore by topics</StyledTitle>
            <Tags items={tags} />
          </StyledTopics>
        )}
        {allPosts.map((item, i) => (
          <Fragment key={item.slug}>
            <PostCard item={item} />
            {i < allPosts.length - 1 && <StyledDivider />}
          </Fragment>
        ))}
      </StyledPosts>
    </>
  )
}

export const getStaticProps = async () => {
  const allPosts = getAllPosts([
    "title",
    "date",
    "slug",
    "author",
    "coverImage",
    "excerpt",
    "tags",
  ])
  const allTags = allPosts.reduce((acc, post) => {
    const tags = post.tags
    if (tags) {
      tags.forEach((item) => {
        if (!acc.includes(item)) {
          acc.push(item)
        }
      })
    }
    return acc
  }, [])
  return {
    props: { allPosts, tags: allTags },
    revalidate: revalidateNext,
  }
}
