import Head from "next/head"

const MetaTags = ({ title, description, url, keywords }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />

      <meta property="og:url" content={url} />
      <meta property="twitter:url" content={url} />
      <link rel="canonical" href={url} />

      <meta name="keywords" content={keywords} />

      <meta charSet="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="lang" content="en-US" />
      <meta name="robots" content="index,follow, max-image-preview:large" />
      <meta
        property="og:image"
        content="https://uicomponentlib.com/assets/icon.png"
      />

      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="uicomponentlib.com" />
      <meta name="twitter:card" content="summary_large_image" />

      <meta
        name="google-site-verification"
        content="d3ZgGUOjRe_WaGoJEL9Xl1F7sXwIycMbyF0kQD8Sl4w"
      />
    </Head>
  )
}

export default MetaTags
